import React from 'react';
import { Link } from 'gatsby';
import RenderConfigFE from '../../../../src/components/render-config-fe';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IFooterProps {
    children?: any;
}

const Footer = (props: IFooterProps) => {
    const logoImage = getConfigFrontEnd({ name: 'LogoImage' });
    const configLogoLink = getConfigFrontEnd({ name: 'LogoLink' });
    return (
        <>
            <section className="section-footer custom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <RenderConfigFE className="copy-right" name="Footer_left_content" type="html" />
                        </div>
                        <div
                            className="col-sm-12 col-md-6 col-lg-6"
                            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Link to={configLogoLink} target="__blank">
                                <img width="260" src={logoImage.url}></img>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-footer-copyright">
                <RenderConfigFE className="copy-right" name="FooterCopyright" type="html" />
            </section>
        </>
    );
};

export default Footer;
