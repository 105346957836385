import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IHeaderProps {
    children?: any;
}

const Header = (props: IHeaderProps) => {
    const configLogoLink = getConfigFrontEnd({ name: 'LogoLink' });
    const logoImage = getConfigFrontEnd({ name: 'LogoImage' });
    const emails = JSON.parse(getConfigFrontEnd({ name: 'Header_email' }));
    const phones = JSON.parse(getConfigFrontEnd({ name: 'Header_phone' }));
    const headerClass = classNames({
        'container-fluid': true
    });

    return (
        <section className="section-header custom">
            <div className={headerClass}>
                <div className="row section-header-container o-flex">
                    <div className="col-sm-12 col-md-4 col-lg-4 section-header-container--email">
                        {emails &&
                            emails.map((item: any, index: number) => {
                                return (
                                    <a href={`mailto:${item}?subject=contact schatting / estimation`} key={index}>
                                        <span className="icon icon-envelope"></span> {item}
                                    </a>
                                );
                            })}
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 section-header-container--logo">
                        <Link to={configLogoLink} target="__blank">
                            <img src={logoImage.url}></img>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 section-header-container--phone">
                        {phones &&
                            phones.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <a href={`tel:${item.phone}`}><span className="icon icon-phone"></span> {item.label}</a>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
