/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CustomerInfo } from '../../../../build-info';
import { getSEO } from '../../../../src/components/use-seo';

export interface ISEO {
    description?: string | '';
    lang?: string | 'en';
    meta?: { name?: string; content?: string; property?: string }[];
    title?: string;
    image?: string
}

function SEO({ description, lang, meta, title, image }: ISEO) {
    const data = getSEO();

    console.log(data);

    return (
        <Helmet
            htmlAttributes={{
                lang: CustomerInfo.defaultLanguage || lang
            }}
            title={title}
            meta={[
                {
                    name: `author`,
                    content: `omnicasa`
                },
                {
                    name: `description`,
                    content: description
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: description
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: image
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: 'omnicasa'
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: description
                }
                // @ts-ignore
            ].concat(meta)}>
            <script type="application/ld+json">
                {`{
                    "@context": "http://schema.org/",
                    "@type": "Product", 
                    "name": "${data.site.siteMetadata.title}", 
                    "description": "${data.site.siteMetadata.description}", 
                    "offers": 
                        { 
                            "@type": "Offer", 
                            "url": "${data.site.siteMetadata.siteUrl}", 
                            "itemCondition": "http://schema.org/NewCondition", 
                            "availability": "http://schema.org/InStock", 
                            "price": "0", 
                            "priceCurrency": "EUR", 
                            "priceValidUntil": "2021-11-20" }, 
                            "review": 
                                { 
                                    "@type": "Review", 
                                    "reviewRating": { "@type": "Rating", "ratingValue": "5", "bestRating": "5" }, 
                                    "author": {            
                                    "@type": "Person", 
                                    "name": "Bernard Vandaele"
                                } 
                        }
                    }`}
            </script>
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
};

export default SEO;
